.instructor-cover{
    font-size: .9rem;
    font-weight: 600;
    margin-bottom: 1rem;
}
small {
    color: #8299b2;
    font-weight: 300;
    margin-left: 5px;
    font-family: "Manrope", sans-serif;
    text-align: center;

}
.btn-instructor{
    font-weight: 500;
    width: 180px;
    font-size: 15px;
    background-color: #20B7F7;
    border: none;
    border-radius: 6px;
    color: white;
    padding: 5px;
}
.instructor-cover img{
    width: 6rem;
    height: 6rem;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    display: flex;
}

 .swiper-pagination .swiper-pagination-bullets .swiper-pagination-horizontal{
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
}