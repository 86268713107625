body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
background-color: grey;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.img-r{
    width: 100%;
   
}
.pagination{
    background-color: azure;
    bottom: -5px;
}
 .swiper-button-next{
    background-color: rgba(0, 0, 0, 0.299);
    color: white;
    padding: 1rem;
    border-radius: 50%;
  
}
.swiper-button-prev {
    background-color: rgba(0, 0, 0, 0.299);
    color: white;
    padding: 1rem;
    border-radius: 50%;
  
}

.swiper-button-prev:after{
    content: 'prev';
    color: white;
    font-size: .8rem;
}
.swiper-button-next:after{
    content: 'next';
    color: white;
    font-size: .8rem;
}

.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 3px;
    left: 0;
    width: 100%;
    color: aliceblue;
    
}
.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet-active {
    background: white;
}

.swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -5px;
}
#description p{
    font-size: 18px;
}
.recommended-card .d-flex img{
    padding: 0 1px;
    width: 100%;
}
.recommended-card img{
    width: 100%;
}
.ill{
    font-size: 14px;
    color:#000 ;
    font-weight: 600;
}
.article{
    font-size: 12px;
    font-weight: 400;
    color: #798998;
}
.people  h6{
      margin-bottom: 0;
        font-size: 14px;
        font-weight: 700;
      }
.interest h6{
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 700;
}
#people h4{
    color: #858585;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 21px;
    margin-top: 2rem;
}
#people .follow-btn{
    border: none;
    background: rgba(183, 209, 249, 0.21);
    color: #3177E0;
    padding: 5px 0;
    border-radius: 8px;
    box-shadow: 1px 1px 1px -5px;
}
.article-img-cover{
    position: relative;
}
.dots{
    top: .5rem;
    right: .5rem;
   position: absolute;
   z-index: 9999;
}
.overlay{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    overflow: hidden;
    background: linear-gradient(180deg, #000000 -20.65%, rgba(0, 0, 0, 0) 48.7%);
    border-radius: 10px;
}
@media screen and (max-width: 992px){
    .overlay{
        position: relative;
        overflow: hidden;
    }
    .article-img{
        width: 100%;
    }
    
}
.badge {
    background-color: #fff5da;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    padding: 5px !important;
    display: inline-block;
    color: #ebab00;
    font-weight: 600;
    font-size: 12px;
  }
  .badge span{
    color: #ebab00;
  }
  .feature-footer{
    display: inline-block;
  }
  .feature-footer p{
   margin-left: 8px;
   margin-top: 14px;
  }
  .text-color{
    color: #20b7f7;
    text-decoration: none;
    font-weight: 500;
  }
  .img4{
    width: 100%;
  }
  @media screen and (max-width: 576px) {
    
      .feature-footer {
          padding: 20px;
          font-size: .9rem;
          font-weight: 600;
      }
  }
  




.menu-link{
    display: grid;
    grid-template-columns: 10rem 3fr 10rem;
    
}
.menu-link ul{
    grid-column: 2/3;
    height: 3rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #ced4da;
    margin-top: 2rem;
    border-radius: 1rem;
    box-shadow: 10px 5px 15px -10px;
    padding-left: 0rem;
}
.menu-link ul li{
    list-style: none;
    text-decoration: none;
}
.menu-link ul li a{
    text-decoration: none;
    color: #6c757d;
    background-color: rgba(255, 255, 255, 0);
    padding: 5px 12px;
    border-radius: 5px;
}
.menu-link ul li a:hover{
    padding: 5px 12px;
    border-radius: 5px;
    color: white;
    background-color: #20b7f7;
}

@media screen and (max-width: 776px){

    .menu-link{
        display: grid;
        grid-template-columns: 3fr ;
        
    }
    .menu-link ul{
        grid-column: 1/3;
        padding-left: 0rem;
    }
  
}
    



.instructor-cover{
    font-size: .9rem;
    font-weight: 600;
    margin-bottom: 1rem;
}
small {
    color: #8299b2;
    font-weight: 300;
    margin-left: 5px;
    font-family: "Manrope", sans-serif;
    text-align: center;

}
.btn-instructor{
    font-weight: 500;
    width: 180px;
    font-size: 15px;
    background-color: #20B7F7;
    border: none;
    border-radius: 6px;
    color: white;
    padding: 5px;
}
.instructor-cover img{
    width: 6rem;
    height: 6rem;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    display: flex;
}

 .swiper-pagination .swiper-pagination-bullets .swiper-pagination-horizontal{
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
}
.course-cover{
    font-size: .9rem;
    font-weight: 600;
    border-radius: 10px;
    box-shadow: 10px 15px 10px -15px;
    margin-bottom: 1rem;
}
small {
    color: #8299b2;
    font-weight: 300;
    margin-left: 5px;
    font-family: "Manrope", sans-serif;

}
.feature-footer p{
    color: #8299b2;
    font-weight: 300;
    margin-left: 5px;
    font-family: "Manrope", sans-serif;

}
.course-para{
    font-weight: 300;
    margin: 5px;
    font-family: "Manrope", sans-serif;
}
.feature-footer{
    padding: 5px;
    font-size: .8rem;
    font-weight: 600;
}
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
}

.container{
    background-color: rgb(234, 234, 234);
}
.profile{
    display: flex;
    margin-top: -6rem;
    z-index: 999999;
    position: relative;
    align-items: center;
    justify-content: center;
    
}
.dp{
    height: 8rem;
    width: 8rem;
    background-color: chartreuse;
    border-radius: 50%;
}
.detail{
    display: flex;
    flex-direction: column;
    text-align: center;
}

.aside .stats .d-flex {
    border-bottom: 1px solid #0c0c0c34;
}
.aside .stats p{
    color:#20B7F7;
    font-weight: 600;
}
.aside .stats .d-flex:hover{
    border-bottom: 1px solid #20B7F7;;
    color: #20B7F7;;
}

.detail .btn-secondary{
color: #20B7F7;
background-color: #E4F6FE;
border: none;
}
