.badge {
    background-color: #fff5da;
    backdrop-filter: blur(10px);
    padding: 5px !important;
    display: inline-block;
    color: #ebab00;
    font-weight: 600;
    font-size: 12px;
  }
  .badge span{
    color: #ebab00;
  }
  .feature-footer{
    display: inline-block;
  }
  .feature-footer p{
   margin-left: 8px;
   margin-top: 14px;
  }
  .text-color{
    color: #20b7f7;
    text-decoration: none;
    font-weight: 500;
  }
  .img4{
    width: 100%;
  }
  @media screen and (max-width: 576px) {
    
      .feature-footer {
          padding: 20px;
          font-size: .9rem;
          font-weight: 600;
      }
  }
  

