.img-r{
    width: 100%;
   
}
.pagination{
    background-color: azure;
    bottom: -5px;
}
 .swiper-button-next{
    background-color: rgba(0, 0, 0, 0.299);
    color: white;
    padding: 1rem;
    border-radius: 50%;
  
}
.swiper-button-prev {
    background-color: rgba(0, 0, 0, 0.299);
    color: white;
    padding: 1rem;
    border-radius: 50%;
  
}

.swiper-button-prev:after{
    content: 'prev';
    color: white;
    font-size: .8rem;
}
.swiper-button-next:after{
    content: 'next';
    color: white;
    font-size: .8rem;
}

.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 3px;
    left: 0;
    width: 100%;
    color: aliceblue;
    
}
.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet-active {
    background: white;
}

.swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -5px;
}